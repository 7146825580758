var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "approval-page" },
    [
      _c(
        "header",
        { staticClass: "df fdr alc jc-sb" },
        _vm._l(_vm.tab_list[_vm.userInfo.user_type], function (item, index) {
          return _c(
            "div",
            {
              key: index,
              class:
                item.index === _vm.current_tab
                  ? "select-item tab-item"
                  : "tab-item",
              on: {
                click: function ($event) {
                  return _vm.selectTab(item.title)
                },
              },
            },
            [
              _vm._v("\n      " + _vm._s(item.title) + "\n      "),
              item.index === _vm.current_tab
                ? _c("div", { staticClass: "line" })
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _vm.approval_list.length > 0
        ? _c(
            "yd-infinitescroll",
            { ref: "approval", attrs: { callback: _vm.getApprovalList } },
            [
              _c(
                "div",
                {
                  staticClass: "approval-list",
                  attrs: { slot: "list" },
                  slot: "list",
                },
                _vm._l(_vm.approval_list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "approval-item df fdc jc-sb",
                      on: {
                        click: function ($event) {
                          return _vm.toDetail(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "top df fdr jc-sb" }, [
                        _c("span", { staticClass: "lh40" }, [
                          _vm._v("订单编号：" + _vm._s(item.pay_order_sn)),
                        ]),
                        _vm.current_tab === 1
                          ? _c("div", { staticClass: "status1 lh40" }, [
                              _vm._v("待我审批"),
                            ])
                          : _vm._e(),
                        _vm.current_tab === 2 && item.status !== 12
                          ? _c("div", { staticClass: "status2" }, [
                              _vm._v(
                                "\n             " +
                                  _vm._s(
                                    _vm.current_tab_is_2[item.examine_status]
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm.current_tab === 2 && item.status == 12
                          ? _c("div", { staticClass: "status2" }, [
                              _vm._v("审批失败"),
                            ])
                          : _vm._e(),
                        _vm.current_tab === 3
                          ? _c("div", { staticClass: "status1 lh40" }, [
                              _vm._v("待审批"),
                            ])
                          : _vm._e(),
                        _vm.current_tab === 4
                          ? _c("div", { staticClass: "status2" }, [
                              _vm._v("已被批准"),
                            ])
                          : _vm._e(),
                        _vm.current_tab === 5 && item.status !== 12
                          ? _c("div", { staticClass: "status2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.current_tab_is_2[item.examine_status]
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm.current_tab === 5 && item.status == 12
                          ? _c("div", { staticClass: "status2" }, [
                              _vm._v("审批失败"),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "middle df fdr jc-sb alc" }, [
                        _c("div", { staticClass: "buyer-box df fdc jc-sb" }, [
                          _c("div", [
                            _vm._v("所在小组：" + _vm._s(item.group_name)),
                          ]),
                          _c("div", [
                            _vm._v(
                              "预算类型：" + _vm._s(item.budget_type_name)
                            ),
                          ]),
                          _c("div", [
                            _vm._v("采购人：" + _vm._s(item.nickname)),
                          ]),
                        ]),
                        _c("div", { staticClass: "price" }, [
                          _vm._v("￥" + _vm._s(item.total_amount)),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "df fdr more" },
                        [
                          _vm._l(
                            item.base_pic.slice(0, 5),
                            function (i, index_img) {
                              return _c("img", {
                                key: index_img,
                                staticClass: "goods-img",
                                attrs: { src: i, alt: "approval_img" },
                              })
                            }
                          ),
                          item.base_pic.length > 4
                            ? _c("div", { staticClass: "img_more" }, [
                                _vm._v("..."),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                }),
                0
              ),
              _c("span", { attrs: { slot: "doneTip" }, slot: "doneTip" }, [
                _vm._v("-没有更多了-"),
              ]),
            ]
          )
        : _vm.is_request && _vm.approval_list.length === 0
        ? _c(
            "div",
            { staticClass: "empty-box" },
            [_c("Empty", { attrs: { tip: "列表空空如也～" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
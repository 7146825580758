<template>
  <div class="approval-page">
    <header class="df fdr alc jc-sb">
      <div v-for="(item,index) in tab_list[userInfo.user_type]" :key="index"
           :class="item.index===current_tab? 'select-item tab-item':'tab-item'" @click="selectTab(item.title)">
        {{item.title}}
        <div class="line" v-if="item.index===current_tab"></div>
      </div>
    </header>
    <yd-infinitescroll v-if="approval_list.length>0" :callback="getApprovalList" ref="approval">
      <div class="approval-list" slot="list">
        <div class="approval-item df fdc jc-sb" v-for="(item,index) in approval_list" :key="index"
             @click="toDetail(item)">
          <div class="top df fdr jc-sb">
            <span class="lh40">订单编号：{{item.pay_order_sn}}</span>
            <div class="status1 lh40" v-if="current_tab===1">待我审批</div>
            <div class="status2" v-if="current_tab===2&&item.status!==12">
               {{current_tab_is_2[item.examine_status]}}
            </div>
            <div class="status2" v-if="current_tab===2&&item.status==12">审批失败</div>
            <div class="status1 lh40" v-if="current_tab===3">待审批</div>
            <div class="status2" v-if="current_tab===4">已被批准</div>
            <div class="status2" v-if="current_tab===5&&item.status!==12"> {{current_tab_is_2[item.examine_status]}}</div>
            <div class="status2" v-if="current_tab===5&&item.status==12">审批失败</div>
          </div>
          <div class="middle df fdr jc-sb alc">
            <div class="buyer-box df fdc jc-sb">
              <div>所在小组：{{item.group_name}}</div>
              <div>预算类型：{{item.budget_type_name}}</div>
              <div>采购人：{{item.nickname}}</div>
            </div>
            <div class="price">￥{{item.total_amount}}</div>
          </div>
          <div class="df fdr more">
            <img class="goods-img" v-for="(i,index_img) in item.base_pic.slice(0,5)" :key="index_img" :src="i" alt="approval_img">
            <div v-if="item.base_pic.length>4" class="img_more">...</div>
          </div>
        </div>
      </div>
      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip">-没有更多了-</span>
    </yd-infinitescroll>
    <!-- 空视图 -->
    <div class="empty-box" v-else-if="is_request&&approval_list.length===0">
      <Empty tip="列表空空如也～"></Empty>
    </div>
  </div>
</template>

<script>
import {getExamineList} from "@/services/my";
import Empty from '@/components/empty/Empty'
import { mapState } from "vuex";
export default {
  name: "ApprovalList",
  data() {
    return {
      current_tab: '',//1 待我审批 2我已审批 3待审批 4已被批准
      tab_list: {
        2:[{title:"待我审批",index:1}, {title:"我已审批",index:2}],
        1:[{title:"待审批",index:3},{ title:"已被批准",index:4}, {title:"已被拒绝",index:5}]
        },
      page: 1, // 开始查询的下标
      listRows: 10, // 每次查询的数量
      approval_list: [],
      is_request: 0,
      current_tab_is_2:{1:'审核中',2:'审核通过',3:'审核拒绝',4:'审核失败'} //我已审批内 状态控制

    }
  },
   computed: {
    ...mapState({
      userInfo: state => state.userInfo, //用户信息
    })
  },
  components: {
    Empty
  },
  methods: {
    getParams() {
      let key = Number.parseInt(this.$route.query.key ? this.$route.query.key : 1);
      this.current_tab = key;
      this.getApprovalList()
    },
    //tab切换
    selectTab(index) {
      console.log(index)
      switch(index){
         case "待我审批":
          {
            this.current_tab  = 1;
          }
          break;
        case "我已审批":
          {
            this.current_tab  = 2;
          }
          break;
        case "待审批":
          {
            this.current_tab  = 3;
          }
          break;
        case "已被批准":
          {
            this.current_tab  = 4;
          }
          break;
		    case "已被拒绝":
          {
            this.current_tab  = 5;
            
          }
        break;
          default:
            break;
      }
      this.$router.replace({
        path: '/approval_list',
        query: {
          key:  this.current_tab
        }
      });
      this.approval_list = [];
      this.page = 1;
      this.getApprovalList()

    },
    //详情
    toDetail(item) {
      this.$router.push({
        path: '/approval_detail',
        query: {
          id: item.pay_order_id,
          status: this.current_tab 
        }
      });
    },
    async getApprovalList() {
      try {
        let params = {
          page: this.page,
          listRows: this.listRows,
          status: this.current_tab
        };
        const res = await getExamineList(params);
        this.is_request = 1;
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.approval_list = [...this.approval_list, ...data.list];
          this.total = data.total
          if (this.approval_list.length === data.total) {
            /* 所有数据加载完毕 */
            this.page++;
            this.$refs.approval.$emit('ydui.infinitescroll.loadedDone');
            return;
          }
          /* 单次请求数据完毕 */
          this.page++;
          this.$refs.approval.$emit('ydui.infinitescroll.finishLoad');
        }
      } catch (e) {

      }
    },
  },
  mounted() {
    this.getParams()
  }
}
</script>

<style scoped lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.approval-page {
  .more{
    overflow: hidden;
  }
  header {
    .wh(100%, 0.88rem);
    background: @color-white;
    padding: 0 0.48rem 0 0.6rem;
    .sc(0.28rem, @color-light-grey);

    .tab-item {
      height: 100%;
      line-height: 0.88rem;
      position: relative;
    }

    .select-item {
      .sc(0.28rem, var(--main-color));

    }

    .line {
      position: absolute;
      .wh(0.5rem, 0.04rem);
      border-radius: 0.06rem;
      background: var(--main-color);
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .approval-list {
    padding: 0.22rem 0.3rem;

    .approval-item {
      .wh(100%, 2.9rem);
      padding: 0.2rem;
      background: @color-white;
      border-radius: 0.16rem;
      margin-bottom: 0.2rem;
      position: relative;
      .sc(0.28rem, @color-grey);

      .top {
        height: 0.56rem;

        .lh40 {
          line-height: 0.4rem;
        }

        .status1 {
          .sc(0.28rem, var(--main-color));
        }

        .status2 {
          .wh(1.38rem, 0.56rem);
          line-height: 0.56rem;
          text-align: center;
          .sc(0.24rem, @color-light-grey);
          border-radius: 0.28rem;
          border: 0.02rem solid @color-light-grey;
        }
      }

      .middle {
        .sc(0.24rem, @color-light-grey);
        height: 0.72rem;

        .buyer-box {
          // height: 100%;
          padding-bottom: 2px;

          div {
            line-height: 0.34rem;
          }
        }

        .price {
          .sc(0.36rem, @color-dark-grey);

        }
      }

      .goods-img {
        .wh(0.96rem, 0.96rem);
        object-fit: cover;
        margin-right: 0.2rem;

        &:last-child {
          margin: 0;
        }
      }

      .img_more {
        .wh(0.7rem, 0.96rem);
        line-height: 0.96rem;
        text-align: center;
        border-radius: 0.04rem;
        .sc(0.24rem, var(--main-color));
        margin-left: 0.2rem;
        background: #F3F2F5;
        position: absolute;
        right: 0.2rem;
      }
    }
  }

  /* 列表为空*/

  .empty-box {
    height: ~"calc(100vh - 0.88rem)";;
  }
}

</style>
